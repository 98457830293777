.sidebar{
    display: none;
    background-color: #333333;
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 5;
    transition: all ease-in-out 0.3s;

    /* transition: all 0.5s; */
    flex-direction: column;
    align-items: center;
}
.close{
    width: 100vw;
    height: 5vh;
    
    margin-top: 2vh;
    margin-left: 4vw;
    
    
    
}

.closeicon{
    color: #FFFFFF;
    width: 5rem;
    height: 5rem;
    
    margin-right: 2vw;
    cursor: pointer;
}
.side-con img{
    width: 70vw;
}
.side-con{
    
    margin-top: 2vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 5vh;

}
.nav-btn1{
    display: flex !important;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: #FE9151;
    color: #FFFFFF;
    width: 25rem !important;
    height: 8rem !important;
    border-radius: 0.8rem;
    font-size: 3.2rem !important;
    letter-spacing: 2px !important;
    font-family: 'Montserrat', sans-serif;
}

.side-con a{
    font-size: 4.5rem;
    letter-spacing: 2px;
    text-decoration: none;
    color: #FFFFFF;
    display: inline-block;
    position: relative;
    width: fit-content;
    
  
      
  }
  @media (max-width:600px)  {
    .sidebar{
        display: block;
    }
  }
  @media (min-width:600px) and (max-width: 1000px) {
    
    .sidebar{
        display: block;
    }
    .closeicon{
        
        width: 7rem;
        height: 7rem;
        
        
    }
    .side-con img{
        width: 50vw;
    }
  }

