:root{
    --about-multiply: 1.2;
}
.container-about{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: calc(11rem*var(--about-multiply));
    margin: 4rem 0;
    
    
}
.about-left{
    width: calc(57rem*var(--about-multiply));
    height: calc(57rem*var(--about-multiply));
}
.about-left img{
    width: calc(57rem*var(--about-multiply));
    height: calc(57rem*var(--about-multiply));
}
.about-right{
    display: flex;
    flex-direction: column;
    width: calc(66rem*var(--about-multiply));
    /* height: calc(68.5rem*var(--about-multiply)); */
    height: fit-content;
    
}

.about-right h1{
    font-size: calc(4.8rem*var(--about-multiply));
    color: #333333;
    margin-bottom: calc(2rem*var(--about-multiply));
}
.about-right p{
    font-size: calc(1.7rem*var(--about-multiply));
    color: #535F5E;
    margin-bottom: calc(2rem*var(--about-multiply));
    line-height: 3rem;
}
.ben{
    display: flex;
    align-items: flex-start;
    width: fit-content;
    

}
.about-img{
    width: calc(6.5rem*var(--about-multiply));
    height: calc(6.5rem*var(--about-multiply));
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3.4rem;
}
.text-about{
    width: calc(56rem*var(--about-multiply));
}
.text-about h2{
    font-size: calc(2.4rem*var(--about-multiply));
    line-height: 4rem;
}
.text-about p{
    font-size: calc(1.6rem*var(--about-multiply));
    line-height: 3rem;
    color: #788281;
}
@media (max-width:600px)  {
    .about-left{
        display: none;
    }
    .about-right{
        width: 90vw;
        margin-top: 5rem;
    }
    .about-right h1{
        font-size: 4rem;
        color: #333333;
        margin-bottom: 2rem;
    }
    .about-right p{
        font-size: 1.2rem;
        
        margin-bottom: 2rem;
        line-height: 2rem;
    }
    .about-img{
        width: 5rem;
        height: 5rem;
        border-radius: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 2rem;
    }
    .about-img img{
        width: 3rem !important;
        height: 3rem !important;
    }
    .text-about{
        width: 70%;
    }
    .text-about h2{
        font-size: 1.5rem;
        line-height: 2.5rem;
    }
    .text-about p{
        font-size: 1rem;
        line-height: 2rem;
        
    }

}
@media (min-width:600px) and (max-width: 1000px) {
    .about-left{
        display: none;
    }
    .about-right{
        width: 90vw;
        margin-top: 7rem;
    }
    .about-right h1{
        font-size: 7rem;
        color: #333333;
        margin-bottom: 2rem;
    }
    .about-right p{
        font-size: 2rem;
        
        margin-bottom: 2rem;
        line-height: 3rem;
    }
    .about-img{
        width: 7rem;
        height: 7rem;
        border-radius: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 2rem;
    }
    .about-img img{
        width: 4rem !important;
        height: 4rem !important;
    }
    .text-about{
        width: 70%;
    }
    .text-about h2{
        font-size: 2.5rem;
        line-height: 4rem;
    }
    .text-about p{
        font-size: 1.5rem;
        line-height: 2.8rem;
        
    }
}
/* @media (min-width: 1000px) and (max-width: 1300px)  {
    :root{
        --about-multiply: 0.9;
    }
}
@media (min-width: 1300px) and (max-width: 1600px)  {
    :root{
        --about-multiply: 0.9;
    }
}
@media (min-width: 2560px) and (max-width: 3839px)  {}
 */
 @media (min-width: 3840px) {
    .about-right p {
        
        line-height: 6rem;
      }
      .text-about h2 {
        
        line-height: 8rem;
      }
      .about-img img{
        width: 8.8rem !important;
        height: 8.8rem !important;
      }
 }