:root{
    --project-multiply: 1
}
.project-container{
    display: flex;
    flex-direction: column;
    margin: calc(12.4rem*var(--project-multiply)) calc(20.5rem*var(--project-multiply));
    height: 80vh;
    

}
.pro-top{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: calc(7.3rem*var(--project-multiply));
}
.pro-top h1{
    font-size: calc(5.8rem*var(--project-multiply));
    color: #333333;
}
.pro-nav{
    width: calc(52.8rem*var(--project-multiply));
    height: calc(5.8rem*var(--project-multiply));
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: calc(1.7rem*var(--project-multiply));
    color: #333333;
    
}
.pro-yellow-nav{
    width: calc(10.7rem*var(--project-multiply));
    height: calc(4.5rem*var(--project-multiply));
    background-color: #FE9347;
    color: #ffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: calc(10rem*var(--project-multiply));
}

.projects{
    display: flex;
    position: relative;
    align-items: center;
    
    
}

.pro{
    width: 90%;
    display: flex;
    justify-content: space-around;
    justify-self: center;
    margin-left: 4vw;
}
.projects button{
    position: absolute;
    right: 0;
    width: calc(4.5rem*var(--project-multiply));
    height: calc(4.5rem*var(--project-multiply));
    cursor: pointer;
    border: none;
    border-radius: calc(10rem*var(--project-multiply));
     /* border: none; */
     background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2rem 3rem 8rem ;
    box-shadow: -1px 1px 20px -4px rgba(253,98,12,0.54);
-webkit-box-shadow: -1px 1px 20px -4px rgba(253,98,12,0.54);
-moz-box-shadow: -1px 1px 20px -4px rgba(253,98,12,0.54);
}
#project-leftbtn{
    left: 0;
}
#project-leftarr{
    
    transform: rotate(180deg);
    
}
.projects button img{
    width: calc(1.4rem*var(--project-multiply));
    height: calc(2.8rem*var(--project-multiply));
    border: none;

}
.projects img{
    width: calc(32.3rem*var(--project-multiply));
    height: calc(45rem*var(--project-multiply));
    overflow: hidden;
    border: 1px solid #FE9347;
    border-radius: calc(1rem*var(--project-multiply));
    
}
.pro-text{
    width: calc(27.3rem*var(--project-multiply));
    height: calc(38.2rem*var(--project-multiply));
    overflow: hidden;
    border: 1px solid #FE9347;
    border-radius: calc(1rem*var(--project-multiply));
    
}
.pro-text div{
    padding: 0 calc(3.4rem*var(--project-multiply));
}
.pro-text h2{
    margin-top: calc(2.2rem*var(--project-multiply));
    font-size: calc(1.7rem*var(--project-multiply));
    margin-bottom: calc(2.2rem*var(--project-multiply));
}
.pro-text p{
    font-size: calc(1.5rem*var(--project-multiply));
    color: #A1A1A1;
    margin-bottom: calc(1.7rem*var(--project-multiply));
}
.date{
    font-size: calc(1.2rem*var(--project-multiply));
    color: #333333 !important;
    
}
.pro-text img{
    height: 50%;
    width: 100%;
    border: none;
    border-radius: 0;
}
.navigation{
    margin-top: calc(6.6rem*var(--project-multiply));
    width: 93%;
    display: flex;
    justify-content: center;
    column-gap: calc(1.5rem*var(--project-multiply));
}
.navigation button{
    width: calc(1.2rem*var(--project-multiply));
    background-color: #EFF0F4;
    height: calc(1.2rem*var(--project-multiply));
    border-radius: calc(50rem*var(--project-multiply));
    border: none;
}
.yellow-navigation{
    background-color: #FE9347 !important;
}
.note{
    margin-top: calc(1.4rem*var(--project-multiply));
    text-align: center;
    color: #FE9347;
    opacity: 0.7;
    font-size: calc(1.5rem*var(--project-multiply));
}

@media (max-width:600px)  {
    .note{
        
        font-size: calc(1.2rem*var(--project-multiply));
    }
    .navigation{
        display: none;
    }
    .project-container{
        
        height: 55vh;
        margin: 0;
        margin-bottom: 2rem !important;
        width: 100vw;
        overflow-x: hidden;
        
    
    }
    .pro-top{
        display: flex;
        flex-direction: column;
        justify-content: unset;
        align-items: center;
        margin-bottom: calc(7.3rem*var(--project-multiply));
    }
    .pro-top h1{
        font-size: 3rem;
        
    }
    .pro-nav{
        margin-top: 2rem;
        margin-bottom: 2rem;
        width: 90vw;
        height: auto;
        
        font-size: 1.2rem;
        
        
    }
    .pro-yellow-nav{
        width: 15vw;
        height: 3vh;
        background-color: #FE9347;
        color: #ffff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10rem;
    }
    .projects{
        margin-left: 2vw;
        width: 100vw;
        display: flex;
      justify-content: unset !important;
        column-gap: 2vw;
        
    }
    .projects img{
        width: 40vw;
        height: 30vh;
        overflow: hidden;
        border: 1px solid #FE9347;
        border-radius: 2rem;
        
    }
    .pro-text{
        width: 40vw;
        height: 30vh;
        overflow: hidden;
        border: 1px solid #FE9347;
        border-radius: 2rem;
        
    }
    .projects button{
        position: absolute;
        z-index: 2;
        right: 2vw;
        
            width: 3rem;
            height: 3rem;
            
            
            border-radius: 10rem;
            
            -webkit-box-shadow: 0px 0px 50px 5px rgba(254,147,71,0.34); 
box-shadow: 0px 0px 50px 5px rgba(254,147,71,0.34);
            
        
    }
    .projects button img{
        width: 1rem;
        height: 2rem;
        
    
        
    
    }
    

  
    
    
}
@media (min-width:600px) and (max-width: 1000px) {
    .navigation{
        display: none;
    }
    .project-container{
        margin: 0;
        height: 61vh;
        margin-bottom: 2rem !important;
        width: 100vw;
        overflow-x: hidden;
        
    
    }
    .pro-top{
        display: flex;
        flex-direction: column;
        justify-content: unset;
        align-items: center;
        margin-bottom: calc(7.3rem*var(--project-multiply));
    }
    .pro-top h1{
        font-size: 5.5rem;
        
    }
    .pro-nav{
        margin-top: 2rem;
        margin-bottom: 2rem;
        width: 90vw;
        height: auto;
        
        font-size: 2.2rem;
        
        
    }
    .pro-yellow-nav{
        width: 17vw;
        height: 5vh;
        background-color: #FE9347;
        color: #ffff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10rem;
    }
    .projects{
        margin-left: 2vw;
        width: 100vw;
        display: flex;
      justify-content: unset !important;
        column-gap: 2vw;
        
    }
    .projects img{
        width: 35vw;
        height: 40vh;
        overflow: hidden;
        border: 1px solid #FE9347;
        border-radius: 2rem;
        
    }
    .pro-text{
        width: 35vw;
        height: 40vh;
        overflow: hidden;
        border: 1px solid #FE9347;
        border-radius: 2rem;
        
    }
    .pro-text img{
        height: 60%;
    }
    .pro-text div{
        margin-left: 1vw;
       
    }
    .pro-text h2{
        font-size: 1.8rem;
        margin-bottom: 1vh;
    }
    .pro-text p{
        font-size: 1.5rem;
        margin-bottom: 1vh;
    }

    .projects button{
        position: absolute;
        z-index: 2;
        right: 2vw;
        
            width: 5rem;
            height: 5rem;
            
            
            border-radius: 10rem;
            
            -webkit-box-shadow: 0px 0px 50px 5px rgba(254,147,71,0.34); 
box-shadow: 0px 0px 50px 5px rgba(254,147,71,0.34);
            
        
    }
    .projects button img{
        width: 2rem;
        height: 3rem;
        
    
        
    
    }
}
/* @media (min-width: 1000px) and (max-width: 1300px)  {
    :root{
        --project-multiply: 0.8;
    }
}
@media (min-width: 1300px) and (max-width: 1600px)  {
    :root{
        --project-multiply: 0.8;
    }
    .project-container{
        
        height: 70vh;
        
    
    }
}
@media (min-width: 2560px) and (max-width: 3839px)  {}
@media (min-width: 3840px) {} */