:root{
    --price-multiply: 1
    
}
.pricing-container{
    margin-top: calc(12rem*var(--price-multiply));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.pricing-container h1{
    color: #333333;
    font-size: calc(4.8rem*var(--price-multiply));
    width: fit-content;
    margin-bottom: calc(2rem*var(--price-multiply));
}
.pricing-container p{
    color: #535F5E;
    font-size: calc(1.8rem*var(--price-multiply));
    width: fit-content;
    
    
}

.plan-nav{
    width: calc(42.1rem*var(--price-multiply));
    height: calc(7.4rem*var(--price-multiply));
    
    
    
}

.plan-nav button{
    border: none;
    width: 50%;
    height: 100%;
    font-size: calc(1.9rem*var(--price-multiply));
    height: calc(7.4rem*var(--price-multiply));
    font-family: 'Montserrat', sans-serif;
    
    
}
.plan-nav button{
    background-color: rgba(254, 147, 71, 0.2);
    color: #FE9347;
    border-radius: 0 2.2rem 2.2rem 0;
}
.plan-nav button:first-child{
    background-color: #FE9347 !important;
    color: #FFFFFF !important;
    border-radius: 2.2rem 0 0 2.2rem;
}
.pricing{
    margin-top: calc(7rem*var(--price-multiply));
    display: flex;
    flex-direction: row;
    column-gap: calc(1.6rem*var(--price-multiply));
}
.pricing-con{
    width: calc(40rem*var(--price-multiply));
    height: calc(80rem*var(--price-multiply));
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 2rem 3rem 8rem rgba(253, 98, 12, 0.09);
border-radius: calc(1rem*var(--price-multiply));
}
.pricing-con h3{
    font-size: calc(2.4rem*var(--price-multiply));
    margin-bottom: calc(1.6rem*var(--price-multiply));
    color: #333333;
}
.price{
    display: flex;
    align-items: flex-end;
    height: fit-content;
    margin-bottom: calc(4rem*var(--price-multiply));
    color: #333333;
}
.price h2{
    font-size: calc(6.4rem*var(--price-multiply));
}
.price h4{
    font-size: calc(2.4rem*var(--price-multiply));
}
.select{
    width: calc(35.2rem*var(--price-multiply));
    border: 1px solid #FE9347;
    color: #FE9347;
    background-color: transparent;
    height: calc(5.7rem*var(--price-multiply));
    border-radius: calc(0.8rem*var(--price-multiply));
    font-size: calc(2.7rem*var(--price-multiply));
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

}
.yellow-select{
    background-color: #FE9347 !important;
    border: none !important;
    color: #FFFFFF !important;
}
.features{
    margin-top: calc(1.5rem*var(--price-multiply));
}
.features div{
    display: flex;
    column-gap: calc(2.4rem*var(--price-multiply));
    padding: 0 calc(4.8rem*var(--price-multiply));
    margin-top: calc(2rem*var(--price-multiply));
}
.features div p{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: calc(1.6rem*var(--price-multiply));
line-height: calc(2.4rem*var(--price-multiply));

/* identical to box height, or 150% */
font-feature-settings: 'pnum' on, 'lnum' on;

/* Text/Body */

color: #808080;


}
.features div img{
    
    width: calc(2.4rem*var(--price-multiply));
    height: calc(2.4rem*var(--price-multiply));
}
.pricing-btn{
    display: none;
}

@media (max-width:600px)  {

    :root{
        --price-multiply: 0.5;
    }
    .pricing-container{
        margin-top: 2rem;
        height: 180vh;
        width: 100vw;
        overflow-x: hidden;
        
        
        
    }
    .pricing-container p {
        text-align: center;
    }
    /* .pricing-con{
        justify-content: center;
    } */
    .pricing{
        display: flex;
        flex-direction: column;
        row-gap: 2vh;

        
    }
    .pricing-con{
        box-shadow: -1px 1px 20px -6px rgba(253,98,12,0.6);
-webkit-box-shadow: -1px 1px 20px -6px rgba(253,98,12,0.6);
-moz-box-shadow: -1px 1px 20px -6px rgba(253,98,12,0.6);
    }
    .pricing-con h3 {
        margin-top: calc(1.6rem*var(--price-multiply));
        margin-bottom: calc(2.6rem*var(--price-multiply));
        
      }
    .features div{
        height: 3vh;
        margin-top: calc(0.8rem*var(--price-multiply));
    }
    .features div p{
        text-align: start;
    }
    /* .focused{
        box-shadow: 2rem 3rem 8rem rgba(253, 98, 12, 0.15);
    }
    .unfocused{
        margin-top: calc(4rem*var(--price-multiply));
        filter: blur(0.5px);
    } */
   
    .btn-price{
        position: absolute;
        display: flex;
        width: 90vw;
        z-index: 4;
        justify-content: space-between;
        
    }
    .pricing-btn{
        
       
        
        
        
        width: 4rem;
        height: 4rem;
        
        border: none;
        border-radius: calc(10rem*var(--price-multiply));
         /* border: none; */
         background: #fff7f1;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 2rem 3rem 8rem rgba(253, 98, 12, 0.2);
    }
    
    .pricing-btn img{
        width: 2rem;
        height: 2rem;
        border: none;

    
    }
    .leftarr-price{
        transform: rotate(180deg);

    }
}
@media (min-width:600px) and (max-width: 1000px) {
    :root{
        --price-multiply: 0.9;
    }
    .pricing-container{
        margin-top: 2rem;
        height: 250vh;
        width: 100vw;
        overflow-x: hidden;
        
        text-align: center;
        
    }
    /* .pricing-con{
        justify-content: center;
    } */
    .pricing{
        position: relative;
        display: flex;
        flex-direction: column;
        row-gap: 3vh;

        
    }
    .pricing-con h3 {
        margin-top: calc(1.6rem*var(--price-multiply));
        margin-bottom: calc(2.6rem*var(--price-multiply));
        
      }
      .features{
        margin-top: 1vh;
      }
    .features div{
        
        height: 4.5vh;
        text-align: start;
        margin-top: calc(0.8rem*var(--price-multiply));
        
    }
    .pricing-con{
        box-shadow: -1px 1px 20px -6px rgba(253,98,12,0.6);
-webkit-box-shadow: -1px 1px 20px -6px rgba(253,98,12,0.6);
-moz-box-shadow: -1px 1px 20px -6px rgba(253,98,12,0.6);
    }
    
    /* .focused{
        box-shadow: 2rem 3rem 8rem rgba(253, 98, 12, 0.15);
    }
    .unfocused{
        margin-top: calc(4rem*var(--price-multiply));
        filter: blur(1.5px);
    } */
    /* .btn-price{
        position: absolute;
        display: flex;
        width: 90vw;
        z-index: 4;
        justify-content: space-between;
        
    }
    .pricing-btn{
        
       
        
        
        
        width: 6rem;
        height: 6rem;
        
        border: none;
        border-radius: calc(10rem*var(--price-multiply));
         /* border: none; */
         /* background: #fff7f1;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 2rem 3rem 8rem rgba(253, 98, 12, 0.2); */
    /* }
    
    .pricing-btn img{
        width: 3rem;
        height: 3rem;
        border: none;

    
    }
    .leftarr-price{
        transform: rotate(180deg);

    }  */
}

/* @media (min-width: 1000px) and (max-width: 1300px)  {
    :root{
        --price-multiply: 0.8;
    }
}
@media (min-width: 1300px) and (max-width: 1600px)  {
    :root{
        --price-multiply: 0.8;
    }
}
@media (min-width: 2560px) and (max-width: 3839px)  {}
@media (min-width: 3840px) {} */
