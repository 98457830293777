:root{
    --form-multiply: 1
}

.form-container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(61rem*var(--form-multiply));
    column-gap: calc(13.8rem*var(--form-multiply));
}
.form-left{
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
}
.form-left h1{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: calc(3.6rem*var(--form-multiply));
line-height: calc(4.4rem*var(--form-multiply));

color: #333333;
margin-bottom: calc(4.2rem*var(--form-multiply));
}
.contact-info{
    display: flex;
    column-gap: calc(1.6rem*var(--form-multiply));
    font-family: 'Khula';
font-style: normal;
font-weight: 400;
font-size: calc(1.8rem*var(--form-multiply));
line-height: calc(2.9rem*var(--form-multiply));
margin-bottom: calc(2.4rem*var(--form-multiply));
align-items: center;

/* #525252 - Dark Grey 2 */
color: #525252;
}
.contact-img{
    display: flex;
justify-content: center;
align-items: center;
padding: calc(2.4rem*var(--form-multiply));

width: calc(4.2rem*var(--form-multiply));
height: calc(4.2rem*var(--form-multiply));
border-radius: calc(1.2rem*var(--form-multiply));
background: rgba(255, 102, 53, 0.1);
}
.contact-img img{
    width: calc(2.4rem*var(--form-multiply));
    height: calc(2.4rem*var(--form-multiply));
}
.form-row input{
    border:0;
    border-bottom: 0.3rem solid #C4C4C4;
    width: calc(53.6rem*var(--form-multiply));
    margin-bottom: calc(4.5rem*var(--form-multiply));
    padding-bottom: calc(1.6rem*var(--form-multiply));
    
font-family: 'Khula';
font-style: normal;
font-weight: 400;
font-size: calc(1.4rem*var(--form-multiply));
line-height: calc(2.2rem*var(--form-multiply));

/* #333333 - Dark Grey */
color: #333333 !important;
}

.first-row{
    
    display: flex;
    column-gap: calc(4rem*var(--form-multiply));
}
.first-row input{
    width: calc(24.8rem*var(--form-multiply)) !important;
}
.btn-div{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: calc(7.8rem*var(--form-multiply));
}
.send{
    background: #FE9347;
border-radius: calc(0.5rem*var(--form-multiply));
border: none;

    width: calc(20rem*var(--form-multiply));
    height: calc(5.4rem*var(--form-multiply));
    font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: calc(2.0rem*var(--form-multiply));
line-height: calc(2.4rem*var(--form-multiply));

color: #FFFFFF;
display: flex;
justify-content: center;
align-items: center;
}
.donut{
    position: absolute;
    z-index: 1;
    width: calc(10.9rem*var(--form-multiply));
    height: calc(10.9rem*var(--form-multiply));
    right: calc(0.4rem*var(--form-multiply));
    top: calc(10.4rem*var(--form-multiply));
}
@media (max-width:600px)  {
    
    .form-container{
        flex-direction: column;
        height: 100vh;

    }
    .form-left{
        
        width: 100vw;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 1vh;
        

    }
    .form-left h1{
        width: 100vw;
        text-align: center;
        font-size: 2rem;
        margin-bottom: 2rem;

    }
    .donut{
        z-index: -1;
    }
    .contact-info{
        margin-left: 2vw;
        column-gap: 2vw;
        font-size: 1.2rem;
        margin-bottom: 0;


    }

    .contact-img{
        width: 4vw;
        height: 4vw;
        border-radius: 0.5rem;
    }
    .donut{
        width: 7rem;
        height: 7rem;
        right: 2vw;

    }
    .form-right{
        margin-top: 5vh;
        width: 90vw;
        
    }
    .first-row{
        column-gap: 5vw;
        
    }
    .form-row{
        row-gap: 2vh;
        margin-top: 2vh;
    }
    .form-row input{
        width: 90vw;
        border-bottom: 0.1rem solid #C4C4C4;
        font-size: 1.5rem;
line-height: 2.2rem;
    }
    .first-row input{
        width: 42.5vw !important;
        
    }
    .btn-div{
        margin-top: 0;
    }
    .send{
        margin-top: 2vh;
        width: 30vw;
        height: 4vh;
        font-size: 1.1rem;
        border-radius: 0.4rem;
    }
    
    
}
@media (min-width:600px) and (max-width: 1000px) {
    .form-container{
        flex-direction: column;
        height: 90vh;

    }
    .form-left{
        
        width: 100vw;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 1vh;
        

    }
    .form-left h1{
        width: 100vw;
        text-align: center;
        font-size: 3rem;
        margin-bottom: 1vh;

    }
    .donut{
        z-index: -1;
    }
    .contact-info{
        margin-bottom: 0;
        margin-left: 2vw;
        column-gap: 2vw;
            font-size: 2rem;

    }

    .contact-img{
        width: 7vw;
        height: 7vw;
        border-radius: 0.8rem;
    }
    .contact-img img{
        width: 6vw;
        height: 6vw;
        
    }
    .donut{
        width: 7rem;
        height: 7rem;
        right: 2vw;

    }
    .form-right{
        margin-top: 5vh;
        width: 90vw;
        
    }
    .first-row{
        column-gap: 5vw;
        
    }
    .form-row{
        row-gap: 3vh;
        margin-top: 3vh;
    }
    .form-row input{
        width: 90vw;
        border-bottom: 0.1rem solid #C4C4C4;
        font-size: 2rem;
line-height: 2.2rem;
    }
    .first-row input{
        width: 42.5vw !important;
        
    }
    .btn-div{
        margin-top: 0;
    }
    .send{
        margin-top: 2vh;
        width: 30vw;
        height: 5vh;
        font-size: 2rem;
        border-radius: 0.4rem;
    }
}

/* @media (min-width: 1000px) and (max-width: 1300px)  {
    :root{
        --form-multiply: 0.8;
    }
}
@media (min-width: 1300px) and (max-width: 1600px)  {
    :root{
        --form-multiply: 0.8;
    }
}
@media (min-width: 2560px) and (max-width: 3839px)  {}
@media (min-width: 3840px) {} */