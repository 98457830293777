:root{
    --service-muliple: 1.2
}
.services-container{
    background: url('../img/service-bg.jpg');
    background-size: 100vw 120vh;
    background-repeat: no-repeat;
    text-align: center;
    width: 100vw;
    background-repeat: no-repeat;
    color: #FFFFFF;
    height: 120vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    
}
.services-container h1{
    
    font-size: calc(4.8rem*var(--service-muliple));
    margin-bottom: calc(2rem*var(--service-muliple));
}
.services-container p{
    font-size: calc(1.8rem*var(--service-muliple));
    
}
.services{
    margin-top: calc(5.7rem*var(--service-muliple));
    display: grid;
    grid-template-columns: max-content max-content max-content;
    justify-content: center;
    column-gap: calc(8.3rem*var(--service-muliple));
    row-gap: calc(7.3rem*var(--service-muliple));
}
.text-service{
    display: flex;
    flex-direction: column;
    
    
    height: calc(16.8rem*var(--service-muliple));
}
.services h2{
    font-size: calc(2.48rem*var(--service-muliple));
    margin-bottom: calc(3.2rem*var(--service-muliple));

}
.services p{
    font-size: calc(1.6rem*var(--service-muliple));
}
.services-child{
    display: flex;
    flex-direction: column;
    text-align: start;
    align-items: flex-start;
    width: calc(35.8rem*var(--service-muliple));
    
}

.services-child img{
    width: calc(8.7rem*var(--service-muliple));
    height: calc(8.7rem*var(--service-muliple));
}
.readmore{
    
    display: flex;
    align-items: center;

}
.readmore a{
    color: #F18930;
    text-decoration: none;
    font-size: calc(1.7rem*var(--service-muliple));
}
.readmore img{
    
    
    width: calc(1.23rem*var(--service-muliple));
    height: calc(1.23rem*var(--service-muliple));
}
@media (max-width:600px)  {
    .services-container{
        
        background-size: 100vw 120vh;
        height: 125vh;
        
        
        
    }
    .services-container h1{
    
        font-size: 3rem;
        margin-bottom: 2rem;
    }
    .services-container p{
        font-size: 1.5rem;
        
    }
    .services{
        margin-top: 5.5rem;
        display: grid;
        width: 100vw !important;
        grid-template-columns: max-content max-content;
        justify-content: center;
        column-gap: 5vw;
        row-gap: 5rem;
    }
    .services-child{
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        width: 40vw;
        
    }
    .services h2{
        font-size: 1.5rem;
        margin-bottom: 1.8rem;
    
    }
    .services p{
        font-size: 1rem;
    }
    .services-child img{
        width: 5rem;
        height: 5rem;
    }
    .text-service{
        height: 15vh;
    }
    .readmore a{
        font-size: 1.4rem;
    }
    .readmore img{
    
    
        width: 1rem;
        height: 1rem;
    }


}
@media (min-width:600px) and (max-width: 1000px) {
    .services-container{
        
        background-size: 100vw 135vh;
        height: 135vh;
        margin-bottom: 4vh;
        
        
        
    }
    .services-container h1{
    
        font-size: 6rem;
        margin-bottom: 2rem;
    }
    .services-container p{
        font-size: 2.5rem;
        
    }
    .services{
        margin-top: 5.5rem;
        display: grid;
        width: 100vw !important;
        grid-template-columns: max-content max-content;
        justify-content: center;
        column-gap: 5vw;
        row-gap: 5rem;
    }
    .services-child{
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        width: 40vw;
        
    }
    .services h2{
        font-size: 3rem;
        margin-bottom: 1.8rem;
    
    }
    .services p{
        font-size: 1.8rem;
    }
    .services-child img{
        width: 8rem;
        height: 8rem;
    }
    .text-service{
        height: 22vh;
    }
    .readmore a{
        font-size: 2rem;
    }
    .readmore img{
    
    
        width: 2rem;
        height: 2rem;
    }
}
/* @media (min-width: 1000px) and (max-width: 1300px)  {
    :root{
        --service-muliple: 0.9;
    }
}
@media (min-width: 1300px) and (max-width: 1600px)  {
    :root{
        --service-muliple: 0.9;
    }
}
@media (min-width: 2560px) and (max-width: 3839px)  {}
@media (min-width: 3840px) {} */
