:root{
    --nav-multiply: 1.3;
}
.nav-container{
    display: flex;
    justify-content: space-between;
    padding: 3rem 7.38rem;

}
.nav-container img{
    width: calc(16.015rem*var(--nav-multiply));
    height: calc(4.227rem*var(--nav-multiply));
}
.nav-headings{
    display: flex;
    align-items: center;

}
.nav-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: none;
    background-color: #FE9151;
    color: #FFFFFF;
    width: calc(11.8rem*var(--nav-multiply));
    height: calc(3.9rem*var(--nav-multiply));
    border-radius: calc(0.4rem*var(--nav-multiply));
    font-size: calc(1.6rem*var(--nav-multiply));
    font-family: 'Montserrat', sans-serif;

}
.anchors{
    margin-right: calc(2vw*var(--nav-multiply));
    display: flex;
    flex-direction: row;
    column-gap: calc(4rem*var(--nav-multiply));
}

.anchors a{
    font-size: calc(1.6rem*var(--nav-multiply));
    text-decoration: none;
    color: #FFFFFF;
    display: inline-block;
    position: relative;
    width: fit-content;
    transition: all 0.7s ease-in-out;
    scroll-behavior: smooth;
    
  
      
  }
  .anchors a:hover{
      color: #FE9151;
      opacity: 1;
  }
  .anchors a::after {
      
      content: '';
      position: absolute;
      width: 70%;
      transform: scaleX(0);
      height: 0.3rem;
      
      bottom: -0.5rem;

      left: 0;
      background: #FE9151;
  border-radius: 0.2rem;
      transform-origin: bottom right;
      transition: transform 0.35s ease-out;
    }
    
  .anchors a:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
    .hammenu{
        display: none;
    }
    /* 
    @media (min-width:600px) and (max-width: 1000px) {} */
    @media (max-width:600px)  {
        .hammenu{
            display: block;
        }
        .nav-container{
            display: flex;
            justify-content: space-between;
            padding: 3vh 3vw;
            align-items: center;
        
        }
        .nav-container img{
            width: 30vw;
            height: auto;
        }
        .nav-headings{
            display: none;
        }
        .hamburger{
            display: block;
            width: 10vw;
            height: auto;
            color: #FFFFFF;
            cursor: pointer;
        }
    }

    @media (min-width:600px) and (max-width: 1000px) {
        .hammenu{
            display: block;
        }
        .nav-container{
            display: flex;
            justify-content: space-between;
            padding: 3vh 3vw;
            align-items: center;
        
        }
        .nav-container img{
            width: 30vw;
            height: auto;
        }
        .nav-headings{
            display: none;
        }
        .hamburger{
            display: block;
            width: 10vw;
            height: auto;
            color: #FFFFFF;
            cursor: pointer;
        }
    }

    /* @media (min-width: 1000px) and (max-width: 1300px)  {
        :root{
            --nav-multiply: 1;
        }
    }
    @media (min-width: 1300px) and (max-width: 1600px)  {
        :root{
            --nav-multiply: 1;
        }
    }
    @media (min-width: 2560px) and (max-width: 3839px)  {

    }
    @media (min-width: 3840px) {

    } */
